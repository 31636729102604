import { Navigate } from 'react-router-dom';
import * as Account from 'pages/Account';
import * as Tasks from 'pages/Tasks';

const routes = [
  { path: '/', element: <Navigate to="/tasks" replace={true} />, exact: true, logged: true, redirect: '/login' },
  { path: '/tasks', element: <Tasks.List />, logged: true, redirect: '/login' },
  { path: '/tasks/:date/:id', element: <Tasks.Details />, logged: true, redirect: '/login' },

  { path: '/login', element: <Account.Login />, logged: false, redirect: '/' },

  // { path: '*', element: <Common.NotFound />, skip_auth: true }
];

export default routes;
