import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';

import styles from './image-preview.module.css';

const ImagePreview = ({ src, alt, style, className, modalClassName }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <img
        src={src}
        alt={alt}
        className={classNames(styles.image, className)}
        style={style}
        onClick={() => setIsOpen(true)}
        loading="lazy"
      />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box className={classNames(styles.modal, modalClassName)}>
          <IconButton onClick={() => setIsOpen(false)} className={styles.close}>
            <CloseIcon />
          </IconButton>
          <div className={styles.content}>
            <img src={src} alt={alt} className={styles.detailImage} loading="lazy" />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ImagePreview;
