import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../helpers/api';
import { selectItem } from '../store/app/getters';
import { setItem } from '../store/app/actions';

const def = {
  isPending: false,
  isSuccess: false,
  isFail: false,
};

const useApiQuery = ({ name = [], path, skip, mapper = (data) => data, isHard = false, skip_history = false, polling_interval }) => {
  const interval = useRef(null);
  const dispatch = useDispatch();
  const [ toUpdate, setUpdate ] = useState(false);

  const apiPath = [ 'api', path ];
  const info = useSelector(selectItem(apiPath, { ...def }));

  const { isPending, isSuccess, isFail, error, status } = info;
  const data = useSelector(selectItem(name));

  const update = () => {
    dispatch(setItem(apiPath, Object.assign({}, info, { ...def })));
    setTimeout(() => setUpdate(!toUpdate), 0);
  };

  useEffect(() => {
    if (!(isSuccess || isPending || isFail || skip) || skip_history) {
      dispatch(setItem(apiPath, Object.assign({}, info, { isPending: true })));

      api.get(path)
        .then(res => {
          const { error } = res.data;
          if (error) {
            dispatch(setItem(apiPath, Object.assign({}, info, { isPending: false, isFail: true, error: error })));
          } else {
            dispatch(setItem(name, mapper(res.data, data), { isHard: isHard }));
            dispatch(setItem(apiPath, Object.assign({}, info, { isPending: false, isSuccess: true })));
          }
        })
        .catch(error => {
          const result = error && error.toJSON();
          // const result = error && JSON.stringify(error);
          dispatch(setItem(apiPath, Object.assign({}, info, { isPending: false, isFail: true, error: result.message, status: result.status })));
        });
    }

    if (polling_interval) {
      interval.current = setInterval(() => setUpdate(!toUpdate), polling_interval);
    }

    return () => interval.current && clearInterval(interval.current);
    //eslint-disable-next-line
  }, [path, toUpdate]);

  return { isPending, isSuccess, isFail, data, error, status, update };
};

export default useApiQuery;
