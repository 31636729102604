import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const NotificationItem = ({ id, type = 'info', content, timeout = 5000, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={true}
      onClose={() => onClose(id)}
      autoHideDuration={timeout}
      key={id}
    >
      <MuiAlert
        onClose={() => onClose(id)}
        elevation={6}
        variant="filled"
        severity={type}
        sx={{ width: '100%' }}
      >
        {content}
      </MuiAlert>
    </Snackbar>
  );
};

const Notifications = ({ items, onClose }) => {
  return (
    <>
      {items && items.map(item => (
        <NotificationItem {...item} onClose={onClose} key={item.id} />
      ))}
    </>
  );
};

export default Notifications;
