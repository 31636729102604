import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { mainListItems, secondaryListItems } from './listItems';
import Notifications from 'components/Notifications';
import { selectItem } from 'store/app/getters';
import { sliceItem } from 'store/app/actions';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     '& .MuiDrawer-paper': {
//       position: 'relative',
//       whiteSpace: 'nowrap',
//       width: drawerWidth,
//       transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       boxSizing: 'border-box',
//       ...(!open && {
//         overflowX: 'hidden',
//         transition: theme.transitions.create('width', {
//           easing: theme.transitions.easing.sharp,
//           duration: theme.transitions.duration.leavingScreen,
//         }),
//         width: theme.spacing(7),
//         [theme.breakpoints.up('sm')]: {
//           width: theme.spacing(9),
//         },
//       }),
//     },
//   }),
// );

const mdTheme = createTheme({
  palette: {
    danger: {
      dark: '#ffdc33',
      main: '#ffeb3b',
      light: '#fff170',
      contrastText: '#000',
    },
    light: {
      dark: '#ddd',
      main: '#eee',
      light: '#fff',
      contrastText: '#1976d2',
    },
  },
});

const DashboardContent = ({ pageTitle, breadcrumbs, children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const notifications = useSelector(selectItem(['notifications']));
  const open = true;
  // const [open, setOpen] = React.useState(true);
  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };

  const handleCloseNotification = (id) => {
    const index = notifications.findIndex(item => item.id === id);
    if (index !== -1) dispatch(sliceItem(['notifications'], index))
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" style={{ width: "100%" }} open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            {/* <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Skillary :: {pageTitle ? pageTitle : 'Панель менеджера'}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <div>{user.name}</div>
              <Avatar alt={user.name} src={user.image} />
            </Stack>
          </Toolbar>
        </AppBar>
        {/* <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer> */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {breadcrumbs && (
            <Container maxWidth="md" sx={{ mt: 2, mb: 2 }}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/" component={RouterLink}>Главная</Link>
                {breadcrumbs.map(({ name, href }, index) => {
                  if (href) return <Link underline="hover" color="inherit" component={RouterLink} to={href} key={index}>{name}</Link>
                  return <Typography color="text.primary" key={index}>{name}</Typography>
                })}
              </Breadcrumbs>
            </Container>
          )}
          <Container maxWidth="md" sx={{ mt: 4, mb: 6 }}>
            {children}
          </Container>
        </Box>
      </Box>
      <Notifications items={notifications} onClose={handleCloseNotification} />
    </ThemeProvider>
  );
}

export default DashboardContent;
