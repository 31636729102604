import { useRef } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import styles from './file-upload.module.css';

const FileUpload = ({ multiple = false, files, onChange, onDelete }) => {
  const inputRef = useRef(null);

  const handleClickSelect = () => {
    inputRef.current?.click();
  };

  const handleChange = () => {
    if (inputRef.current) onChange(inputRef.current.files);
  };

  return (
    <div>
      {!(files && files.length) && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClickSelect}
        >
          Загрузить фото
        </Button>
      )}
      <input
        type="file"
        accept="image/*"
        multiple={multiple}
        onChange={handleChange}
        className={styles.input}
        ref={inputRef}
      />
      {(files && files.length) &&
        <Grid container spacing={2}>
          {files.map(({ file, url }) => (
            <Grid item xs={4} className={styles.item} key={file}>
              <img className={styles.previewImage} src={url} alt={file} />
              <IconButton onClick={() => onDelete(file)} className={styles.remove}>
                <CloseIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      }
    </div>
  );
};

export default FileUpload;