import { compose } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { appSlice } from './app';

const getInitialUserState = () => {
  const cache = localStorage?.getItem('user');
  if (!cache) return null;

  try {
    return JSON.parse(cache);
  } catch (e) {
    console.warn(e)
  }
}

const getInitialTokensState = () => {
  const cache = localStorage?.getItem('tokens');
  if (!cache) return null;

  try {
    return JSON.parse(cache);
  } catch (e) {
    console.warn(e)
  }
}

const initialState = { verification: {}, constructor: {}, user: getInitialUserState(), show_loader: true, tokens: getInitialTokensState() };

const getStore = (preloadedState = initialState) => {
  const middleware = getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  });

  const devTools = !!((typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose);

  return configureStore({ reducer: appSlice.reducer, middleware, preloadedState, devTools });
};

const store = getStore();

export default store;
