import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';

import styles from './modal.module.css';

const ModalComponent = ({ className, title, children, ...props }) => {
  return (
    <Modal {...props}>
      <Box className={classNames(styles.modal, className)}>
        <IconButton onClick={props.onClose} className={styles.close}>
          <CloseIcon />
        </IconButton>
        {title &&
          <Typography component="h3" variant="h5" textAlign="center" dangerouslySetInnerHTML={{ __html: title }}></Typography>
        }
        <div className={styles.content}>
          {children}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
