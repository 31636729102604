import Chip from '@mui/material/Chip';

const TaskStatus = ({ on_work, is_complete, is_rejected, is_done, oper_complete, oper_approved }) => {
  if (on_work && on_work.now_status) return <Chip label={on_work.now_status} color={on_work.alert ? 'error' : 'light'} />;
  if (on_work && on_work.uid) return <Chip label="В работе" color={on_work.alert ? 'error' : 'light'} />;
  // if (is_rejected)   return <Chip label="Нужно переделать" color="" />;
  if (is_done)       return <Chip label="Завершено" color="success" />;
  if (oper_complete) return <Chip label="Отработано" color="success" />;
  if (oper_approved) return <Chip label="Завершено" color="success" />;
  if (is_complete)   return <Chip label="Требует проверки" color="success" />;
};

export default TaskStatus;
