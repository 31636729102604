import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Layout from 'components/Layout';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TaskStatus from 'components/TaskStatus';
import useApiQuery from 'hooks/useApiQuery';

const statuses = [
  { label: 'Не отработанные', value: 'no_worked' },
  { label: 'Отработанные', value: 'is_worked' },
  // { label: 'В работе', value: 'in_work' },
  { label: 'Показывать все', value: 'all' },
];

const List = () => {
  const [ type, setType ] = useState('all');
  const [ status, setStatus ] = useState(statuses[0].value);
  const [ date, setDate ] = useState(dayjs(new Date()))

  const { data: tasksData } = useApiQuery({
    name: ['opertasks', date.format('YYYY-MM-DD'), type, status],
    path: `/opertasks/${date.format('YYYY-MM-DD')}/${type !== 'all' ? `${type}-` : ''}${status}/`,
    skip_history: true,
    polling_interval: 3000
  });

  return (
    <Layout breadcrumbs={[ { name: 'Домашние задания' } ]}>
      <TableContainer component={Paper}>
        <Stack direction="row" spacing={2} sx={{ mt: 1, ml: 1, mr: 1 }}>
          <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="type"
                value={type}
                onChange={e => setType(e.target.value)}
                label="Тип"
              >
                <MenuItem value="all">Показывать все</MenuItem>
                {tasksData && Object.values(tasksData.types).map(({ uid, name, lang }) => (
                  <MenuItem value={lang} key={lang}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                id="status"
                value={status}
                onChange={e => setStatus(e.target.value)}
                label="Статус"
              >
                {statuses.map(({ label, value }) => (
                  <MenuItem value={value} key={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={date}
                onChange={setDate}
                format="DD.MM.YYYY"
                slotProps={{ textField: { variant: 'standard', sx: { m: 1, minWidth: 120, width: 120 } } }}
              />
            </LocalizationProvider>
          </div>
        </Stack>

        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell width="10%">
                Код
              </TableCell>
              <TableCell width="20%">
                Тип
              </TableCell>
              <TableCell width="20%">
                Статус
              </TableCell>
              <TableCell width="30%">
                Оператор
              </TableCell>
              <TableCell width="10%">
                Телефон
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasksData ? tasksData.response.map(({ uid, date, type_name, on_work, phone, ...rest }) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={uid}
              >
                <TableCell>
                  <Link to={`/tasks/${date}/${uid}`} component={RouterLink}>{uid}</Link>
                </TableCell>
                <TableCell>
                  <Link to={`/tasks/${date}/${uid}`} component={RouterLink}>{type_name}</Link>
                </TableCell>
                <TableCell>
                  <TaskStatus on_work={on_work} {...rest} />
                </TableCell>
                <TableCell>
                  {on_work && on_work.oper_name}
                </TableCell>
                <TableCell width="30%">
                  {phone}
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default List;