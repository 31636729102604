import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import api from 'helpers/api';
import { setItem } from 'store/app/actions';

import styles from './Login.module.css';

const Login = () => {
  const [ error, setError ] = useState(null);
  const dispatch = useDispatch();
  const { register, handleSubmit, control, formState: { errors } } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => {
    api
      .get(`/operAuth/${data.login}/${data.password}/`)
      .then(response => {
        dispatch(setItem(['user'], response.data.response))
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={styles.paper}>
        <Avatar className={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" align="center">
          Вход
        </Typography>
        <Typography component="p" align="center">
          Проверка домашней работы Skillary
        </Typography>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="login"
            label="Логин"
            name="login"
            autoComplete="login"
            autoFocus
            control={control}
            error={!!errors.login}
            helperText={errors.login && errors.login.message}
            {...register('login', { required: true })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Пароль"
            type="password"
            id="password"
            autoComplete="current-password"
            control={control}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
            {...register('password', { required: true })}
          />
          {error && (
            <div className={styles.error}>
              {error}
            </div>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}
          >
            Войти
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
