import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from 'pages/ProtectedRoute';
import { selectItem } from 'store/app/getters';
import routes from 'data/routes';

import './App.css';

const App = () => {
  const isLogged = !!useSelector(selectItem(['user']));
  const user = useSelector(selectItem(['user']));

  useEffect(() => {
    if (user) localStorage?.setItem('user', JSON.stringify(user));
    // eslint-disable-next-line
  }, [user]);

  return (
    <Router>
      <Routes>
        {routes.map(props =>
          <Route
            key={props.path}
            {...props}
            element={
              <ProtectedRoute isAllowed={(props.logged && isLogged) || (!props.logged && !isLogged) || props.skip_auth} redirectPath={props.redirect}>
                {props.element}
              </ProtectedRoute>
            }
          />
        )}
      </Routes>
    </Router>
  );
};

export default App;
