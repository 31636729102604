import { useState } from 'react';
import Link from '@mui/material/Link';
import Modal from 'components/Modal';
import Grid from '@mui/material/Grid';

import styles from './avatar-select.module.css';

const AvatarSelect = ({ type = 1, items, onChange }) => {
  const [ isShowModal, setShowModal ] = useState(false);
  const [ selected, setSelected ] = useState(1);

  const handleClickItem = (index) => {
    setShowModal(false);
    setSelected(index);
    if (onChange) onChange(index);
  };

  if (!items) return null;

  return (
    <div className={styles.root}>
      <div onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
        <img src={items.find(({ uid }) => Number(uid) === Number(selected)).url[type]} className={styles.preview} alt={items.find(({ uid }) => Number(uid) === Number(selected)).name} />
      </div>
      <Link underline="hover" color="inherit" style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>Выбрать эмоцию</Link>

      <Modal
        open={isShowModal}
        onClose={() => setShowModal(false)}
        title="Выбрать эмоцию"
      >
        <Grid container spacing={5} sx={{ mt: 2 }}>
          {items.map(({ uid, name, url }) => (
            <Grid item xs={2.4} onClick={() => handleClickItem(uid)} key={uid} textAlign="center" style={{ fontSize: '12px' }}>
              <img
                src={url[type]}
                alt={name}
                loading="lazy"
                className={styles.listItemImage}
              />
              {name}
            </Grid>
          ))}
        </Grid>
      </Modal>
    </div>
  );
};

export default AvatarSelect;
