import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import Layout from 'components/Layout';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'components/Modal';
import AvatarSelect from 'components/AvatarSelect';
import ImagePreview from 'components/ImagePreview';
import FileUpload from 'components/FileUpload';
import TaskStatus from 'components/TaskStatus';
import useApiQuery from 'hooks/useApiQuery';
import api from 'helpers/api';
import { setItem, pushItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './Details.module.css';

const Details = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ isInWorkOpen, setInWorkOpen ] = useState(false);
  const [ isAddErrorOpen, setAddErrorOpen ] = useState(false);
  const [ isDoneOpen, setDoneOpen ] = useState(false);
  const [ avatar, setAvatar ] = useState(null);
  const [ files, setFiles ] = useState(null);
  const [ comment, setComment ] = useState('');
  const [ accur, setAccur ] = useState(false);
  const { date, id } = useParams();

  const { data: taskData = {}, update: taskUpdate } = useApiQuery({
    name: ['opertasks', id],
    path: `/opertasks/${date}/${id}/`,
    skip: !date || !id
  });

  if (!date || !id) {
    navigate('/tasks');
    dispatch(pushItem(['notifications'], { id, content: 'Не указана дата или id' }));
  }

  const task = (taskData && taskData.response) ? taskData.response[0] : null;
  const dialogs_emotions = taskData?.dialogs_emotions;

  const handleClickStart = () => {
    if (task.on_work && task.on_work.uid) setInWorkOpen(true);
    else handleClickToWork();
  }

  const handleClickToWork = () => {
    setInWorkOpen(false);

    const fd = new FormData();
    fd.append('task_on_work[oper_uid]', user.uid);
    fd.append('task_on_work[task_uid]', id);

    api
      .post(`/opertasks/`, fd)
      .then(response => {
        if (response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: response.data.error, type: 'error' }));
        } else {
          taskUpdate();
          dispatch(pushItem(['notifications'], { id, content: 'Домашняя работа взята в работу', type: 'success' }));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: error.response.data.error, type: 'error' }));
        }
      });
  };

  const handleAddFile = (files) => {
    const fd = new FormData();
    fd.append('file', files[0], files[0].name);

    api
      .post(`/uploadoperfiles/`, fd)
      .then(response => {
        if (response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: response.data.error, type: 'error' }));
        } else {
          setFiles([ response.data.response ]);
          dispatch(pushItem(['notifications'], { id, content: 'Файл загружен', type: 'success' }));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: error.response.data.error, type: 'error' }));
        }
      });
  };

  const handleDeleteFile = () => {
    setFiles(null);
  };

  const handleClickDone = () => {
    setDoneOpen(true);
  };

  const handleClickFinish = () => {
    setDoneOpen(false);

    const fd = new FormData();

    fd.append('task_on_work[oper_uid]', user.uid);
    fd.append('task_on_work[task_uid]', id);
    fd.append('task_on_work[complete]', task.on_work.uid);
    if (accur) fd.append('task_on_work[accur]', accur);

    api
      .post(`/opertasks/`, fd)
      .then(response => {
        if (response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: response.data.error, type: 'error' }));
        } else {
          taskUpdate();
          navigate('/tasks');
          dispatch(pushItem(['notifications'], { id, content: 'Задача завершена', type: 'success' }));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: error.response.data.error, type: 'error' }));
        }
      });
  };

  const handleClickSend = () => {
    if (!(files && files.length && comment && avatar)) return null;

    const fd = new FormData();

    fd.append('create_mark[oper_uid]', user.uid);
    fd.append('create_mark[task_uid]', id);
    fd.append('create_mark[comment]', comment);
    fd.append('create_mark[file]', files[0].url);
    fd.append('create_mark[emotion]', avatar);

    api
      .post(`/opertasks/`, fd)
      .then(response => {
        if (response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: response.data.error, type: 'error' }));
        } else {
          taskUpdate();
          setAvatar(null);
          setFiles(null);
          setComment('');
          setAddErrorOpen(false);
          dispatch(pushItem(['notifications'], { id, content: 'Замечание отправлено', type: 'success' }));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: error.response.data.error, type: 'error' }));
        }
      });
  };

  const handleRejectFile = (uid) => {
    const fd = new FormData();
    fd.append('is_rejected_user_file', uid);

    api
      .post(`/opertasks/`, fd)
      .then(response => {
        if (response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: response.data.error, type: 'error' }));
        } else {
          const index = taskData.response[0].user_files.findIndex(({ uid }) => response.data.response === uid);
          dispatch(setItem(['opertasks', id, 'response', 0, 'user_files', index, 'is_rejected' ], true))
          dispatch(pushItem(['notifications'], { id, content: 'Файл помечен как отклоненный', type: 'success' }));
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(pushItem(['notifications'], { id, content: error.response.data.error, type: 'error' }));
        }
      });
  }

  return (
    <Layout breadcrumbs={[ { name: 'Домашние задания', href: '/tasks' }, { name: 'Карточка задания' } ]}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ mb: 2 }}>
        {task ? (
          <Typography component="h2" variant="h5" color="inherit">
            №{task.uid} {task.type_name}
          </Typography>
        ) : (
          <Skeleton variant="rounded" width={200} height={32} />
        )}

        {task ? (
          !task.is_done && (
            task.on_work && task.on_work.oper_uid === user.uid ? (
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={handleClickDone}
              >
                Завершить
              </Button>
            ) : (
              <Button
                variant="contained"
                color={(task.on_work && task.on_work.uid) ? 'danger' : 'success'}
                size="large"
                onClick={handleClickStart}
              >
                Взять в работу
              </Button>
            )
          )
        ) : (
          <Skeleton variant="rounded" width={170} height={42} />
        )}
      </Stack>

      {task && <TaskStatus {...task} />}

      {task ? (
        <Paper sx={{ mt: 4, mb: 2, p: 2 }}>
          <Grid container spacing={2}>
            {task.user_files.length ? (
              task.user_files.map(({ uid, file_url, is_rejected }) => (
                <Grid item xs={4} key={uid}>
                  <div className={classNames(styles.imageItem, is_rejected && styles.rejected)}>
                    <img src={file_url} alt="" style={{ width: '100%' }} />
                    <div className={styles.imageItemContainer}>
                      {!is_rejected && (
                        <Button
                          size="large"
                          variant="contained"
                          color="error"
                          onClick={() => handleRejectFile(uid)}
                          fullWidth
                          download
                        >
                          Отклонить
                        </Button>
                      )}
                      <Button
                        href={file_url}
                        target="_blank"
                        size="large"
                        variant="contained"
                        color="light"
                        fullWidth
                        download
                      >
                        Скачать
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))
            ) : (
              <Box sx={{ pt: 10, pb: 10, pl: 2, pr: 2, flexGrow: 1 }} textAlign="center">
                <Typography component="p" variant="h5">Нет загруженных файлов</Typography>
              </Box>
            )}
          </Grid>
        </Paper>
      ) : (
        <Skeleton variant="rounded" width="100%" height={200} sx={{ mt: 4, mb: 2 }} />
      )}

      {user && task && task.on_work && task.on_work.oper_uid === user.uid && !isAddErrorOpen && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setAddErrorOpen(true)}
          startIcon={<AddIcon />}
        >
          Добавить ошибку
        </Button>
      )}

      {(task && dialogs_emotions && isAddErrorOpen) && (
        <Paper sx={{ mt: 4, mb: 2, p: 2 }}>
          <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
            <TextareaAutosize
              style={{ flexGrow: '1', resize: 'vertical', padding: '12px' }}
              minRows={7}
              placeholder="Опишите суть ошибки так, чтобы ребенок понял"
              onChange={e => setComment(e.target.value)}
              value={comment}
            />
            <AvatarSelect type={task.type} items={dialogs_emotions} onChange={setAvatar} />
          </Stack>
          <Stack sx={{ mt: 4 }} direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
            <FileUpload
              files={files}
              onChange={handleAddFile}
              onDelete={handleDeleteFile}
            />
            <Button
              variant="contained"
              color="success"
              size="large"
              style={{ flexShrink: 0 }}
              onClick={handleClickSend}
              disabled={!(files && comment && comment.length > 3 && avatar)}
            >
              Сохранить
            </Button>
          </Stack>
        </Paper>
      )}

      {(task && task.aproove && task.aproove.length) ? (
        [...task.aproove].reverse().map(({ uid, comment, emotion, file, oper_uid, task_uid, timestamp }) => (
          <Paper sx={{ mt: 4, mb: 2, p: 2 }} key={uid}>
            <Stack sx={{ mt: 2 }} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Typography component="p" variant='h6'>Оператор №{oper_uid}</Typography>
              <Typography component="p" textAlign="right">{dayjs(timestamp).format('HH:mm DD.MM.YYYY')}</Typography>
            </Stack>
            <Stack sx={{ mt: 2 }} direction="row" spacing={2} alignItems="flex-start">
              <img src={emotion} alt={emotion} />
              <Typography component="p" style={{ flexGrow: 1 }}>{comment}</Typography>
              <ImagePreview src={file} alt="" style={{ width: '200px' }} />
            </Stack>
          </Paper>
        ))
      ) : null}

      {(task && task.on_work) && (
        <>
          <Modal
            open={isInWorkOpen}
            onClose={() => setInWorkOpen(false)}
            title={`Это задание проверяет ${task.on_work.oper_name}.`}
          >
            <Typography component="p" variant="h6" textAlign="center" sx={{ mt: 4 }}>
              Вы уверены что хотите забрать у него эту задачу?
            </Typography>
            <Stack sx={{ mt: 4 }} direction="row" spacing={2} justifyContent="space-between">
              <Button
                variant="contained"
                color="error"
                onClick={handleClickToWork}
                size="large"
              >
                Да, забрать
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => setInWorkOpen(false)}
                size="large"
              >
                Не забирать
              </Button>
            </Stack>
          </Modal>
          <Modal
            open={isDoneOpen}
            onClose={() => setDoneOpen(false)}
            title={`Вы уверены что хотите завершить задачу?`}
          >
            <Typography component="p" variant="h6" textAlign="center" sx={{ mt: 4 }}>
              Действие нельзя будет отменить!
            </Typography>
            <Stack sx={{ mt: 4 }} direction="row" spacing={2} justifyContent="space-between">
              <div>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClickFinish}
                  size="large"
                >
                  Да, завершить
                </Button>
                <FormControlLabel
                  sx={{ ml: 1 }}
                  control={<Checkbox />}
                  label="Выполнено аккуратно"
                  onChange={e => setAccur(e.target.value)}
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setDoneOpen(false)}
                  size="large"
                >
                  Отмена
                </Button>
              </div>
            </Stack>
          </Modal>
        </>
      )}
    </Layout>
  );
};

export default Details;